<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<template>
  <div id="f-book-now" style="width: calc(90vw - 20%) !important; padding: 0">
    <div class="full-load" v-if="saveDataLoader">
      <h1 v-html="loadingMessage"></h1>
    </div>
    <div class="d-flex flex-column-fluid card">
      <div class="container-fluid mb-25">
        <div class="card-body mt-5">
          <!-- <div class="col-md-3">
            <h5>Select Archival</h5>

            <multiselect
              key="id"
              :options="getArchivals ? getArchivals : []"
              placeholder="Select Term "
              label="name"
              track-by="id"
              :preselect-first="false"
              v-model="selected_term"
              @select="changeDate"
            ></multiselect> -->
          <!-- <select
              v-model="archival_period"
              name=""
              class="form-control"
              id=""
            >
              <option
                v-for="(citem, index) in getArchivals"
                :key="index"
                :value="citem.id"
              >
                {{ citem.name }}
              </option>
            </select> -->
          <!-- </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for=""> Select Date</label>
              <input
                style="width: 120%;"
                type="date"
                v-model="getArchivalRenewDate"
                class="form-control"
                id="inputdate"
                name="inputdate"
              />
            </div>
          </div> -->

          <!-- <div v-if="show_term == '0'" class="col-lg-6">
            <h5>Select Date</h5>
            <vc-calendar
              :min-date="date"
              is-expanded
              :attributes="attrs"
              v-model="selectedDate"
              @dayclick="onDayClick"
            />
          </div> -->

          <f-payment
            :enrollData="enrollData"
            :front_status="false"
            :is_both_card="false"
            :is_archival="true"
            :is_renew="true"
          ></f-payment>

          <div
            id="book-footer-temp"
            class="bottom-nav col-lg-12 col-md-12 col-12"
            style="
              position: fixed;
              margin-left: -4rem;

              bottom: 2rem;
              width: calc(90vw - 15%);
            "
          >
            <div class="card">
              <div class="card-body">
                <div>
                  <div
                    class="total_amount"
                    v-if="getAbbreviationCode.data.show_payment_option"
                  >
                    <label for="">
                      <small>Total</small>
                    </label>
                    <h3 class="footer-total-amount">
                      ${{ getTotalArchivalRateAmount | toFixedTwoDigit }}
                    </h3>
                  </div>
                  <button
                    class="btn btn-dark mr-2 mt-3 btn-right"
                    @click="goBack()"
                  >
                    Back
                  </button>
                  <button
                    class="btn btn-success mr-2 mt-3 btn-right"
                    @click="SubmitService()"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="card-footer">
          <button
            class="btn btn-success mr-2 mt-3 btn-right"
            style="float: right"
            @click="submit()"
          >
            Submit
          </button>
          <button
            class="btn btn-dark mr-2 mt-3 btn-right"
            style="float: right"
            @click="goBack()"
          >
            Go Back
          </button>
        </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
// import Multiselect from "vue-multiselect";
import "moment-timezone";
import { mapGetters } from "vuex";
// F:\fingerscanfrontend\finger_scan_frontend\src\pages\backend\appointment\archival\payment.vue
import fPayment from "@/pages/backend/appointment/archival/payment.vue";
import {
  FETCH_F_CLIENT_ARCHIVAL_RATES,
  CHANGE_ARCHIVAL_DATE,
  FETCH_ENROLLMENT,
  FETCH_COUNTRIES,
  FETCH_STATES,
  FETCH_NOTES
} from "@/core/services/store/actions.type";
import {
  STORE_ARCHIVAL_DATE,
  SET_ARCHIVAL_PERIOD_ID,
  SET_F_CLIENT_ENABLE_ON_SITE_PAYMENT,
  SET_ARCHIVAL_RENEW_DATE,
  UPDATE_ABBREVIATION_CODE,
  SET_ARCHIVAL_TOTAL_RATE_AMOUNT,
  SET_PAYMENT_CARD_PROPERTY,
  SET_PAYMENT_CARD_TYPE,
  SET_PAYMENT_CARD_NUMBER,
  SET_PAYMENT_CARD_HOLDER_NAME,
  SET_PAYMENT_CARD_ADDRESS,
  SET_PAYMENT_CARD_CITY,
  SET_PAYMENT_CARD_CVV_NUMBER,
  SET_PAYMENT_CARD_EXPIRY_DATE,
  CARD_COUNTRY,
  CARD_STATE,
  UPDATE_PAYMENT_TYPE,
  SET_PAYMENT_CARD_NUMBER_PHONE,
  SET_PAYMENT_CARD_NUMBER_POSTAL,
  SET_CHECK_F_ABBREVIATION_CODE,
  SET_ARCHIVAL_CUSTOM_DATE,
  SET_ARCHIVAL_CUSTOM_PRICE,
  SET_ARCHIVAL_CUSTOM
} from "@/core/services/store/mutations.type";

// import Title from '../../components/frontend/title.vue';
export default {
  components: { fPayment },
  computed: {
    ...mapGetters([
      "getArchivalDate",
      "getClientId",
      "getLoadingData",
      "getTimeZone",
      "currentUser",
      "getServiceProviderData",
      "getArchivalRates",
      "getTotalServiceAmount",
      "getTotalAdditionalAmount",
      "getfClientRate",
      "getTotalRateAmount",
      "getAddQty",
      "getPromoCode",
      "getAbbreviationCode",
      "updateAbbreviationCode",
      "updatePromoCode",
      "getPaymentType",
      "getClientEnableOnSitePayment",
      "getSettingNote",
      "getPaymentCardNumber",
      "getPaymentCardHolderName",
      "getPaymentCardExpiry",
      "getPaymentCardCvvNumber",
      "getPaymentCardType",
      "getPaymentCardProperty",
      "getPaymentCardHolderAddress",
      "getPaymentCardHolderState",
      "getPaymentCardHolderCountry",
      "getPaymentCardHolderCity",
      "getStates",
      "getCountries",
      "getPaymentCardPhone",
      "getPaymentCardPostalZip",
      "getPaymentCardPhonecode",
      "getQty",
      "getTotalArchivalRateAmount",
      "getArchivalPeriodId",
      "getServiceOriCode",
      "updateServiceOriCode",
      "getServiceTypeCode",
      "getTotalDiscountAmount",
      "getPromoCodeDiscountRate",
      "getArchivalRenewDate",
      "getArchivalCustomPrice",
      "getArchivalCustomDate",
      "getArchivalCustom"
    ])
  },

  data() {
    return {
      archival_period: "",
      archivalItem: 0,
      selected_term: "",
      archival_date: "",
      set_upper_limit: "",
      set_lower_limit: "",
      enrollData: {},
      loadingMessage: "",
      saveDataLoader: false
    };
  },

  created() {
    var data_ = {
      enrollment_id: this.$route.params.enroll_id
    };
    this.$store.commit(
      SET_F_CLIENT_ENABLE_ON_SITE_PAYMENT,
      this.$route.params.onsite
    );
    this.reset();

    this.$store.dispatch(FETCH_COUNTRIES);
    this.$store.dispatch(FETCH_STATES);
    this.$store.dispatch(FETCH_NOTES);

    // this.$store.dispatch(FETCH_ARCHIVALS, "all");
    this.$store.dispatch(FETCH_ENROLLMENT, data_).then(res => {
      this.enrollData = res.data;
      this.$store
        .dispatch(
          FETCH_F_CLIENT_ARCHIVAL_RATES,
          res.data.originating_location_id
        )
        .then(data => {
          this.archivalData = data;
        });

      if (
        !res.data.archival_expiry_date ||
        res.data.archival_expiry_date != ""
      ) {
        this.archival_date = res.data.archival_expiry_date;
        // date.setMonth(date.getMonth()+8))
        this.$store.commit(STORE_ARCHIVAL_DATE, this.archival_date);
        // date.setMonth(date.getMonth()+8)
        var set_date = new Date(this.archival_date);
        this.set_upper_limit = set_date.setMonth(set_date.getMonth() + 3);
        document.getElementById("inputdate").max = moment(
          this.set_upper_limit
        ).format("YYYY-MM-DD");

        this.set_lower_limit = set_date.setMonth(set_date.getMonth() - 6);

        document.getElementById("inputdate").min = moment(
          this.set_lower_limit
        ).format("YYYY-MM-DD");
      }
    });
    // alert(this.attrs[1].dates);

    // this.getTimeZone();
  },

  methods: {
    dateFormat(date) {
      if (date == null || date == "") {
        return;
      }
      return moment(date).format("MM/DD/YYYY");
    },
    // changeDate(option) {
    //   var duration = parseInt(option.duration);

    //   if (!this.archival_date == "") {
    //     var dateObject = new Date(this.archival_date);

    //     dateObject.setFullYear(dateObject.getFullYear() + duration);
    //   } else {
    //     dateObject = new Date();

    //     dateObject.setFullYear(dateObject.getFullYear() + duration);
    //   }
    //   this.getArchivalRenewDate = moment(dateObject).format("YYYY-MM-DD");
    //   var set_date = new Date(this.getArchivalRenewDate);
    //   this.set_upper_limit = set_date.setMonth(set_date.getMonth() + 3);
    //   document.getElementById("inputdate").max = moment(
    //     this.set_upper_limit
    //   ).format("YYYY-MM-DD");

    //   this.set_lower_limit = set_date.setMonth(set_date.getMonth() - 6);
    //   document.getElementById("inputdate").min = moment(
    //     this.set_lower_limit
    //   ).format("YYYY-MM-DD");
    //   console.log(moment(dateObject).format("YYYY-MM-DD"));
    // },
    goBack() {
      // var client_slug = this.$route.params.client_slug;
      this.reset();
      // this.$router.push({
      //   name: "client.archivallist",
      //   params: { client_slug: client_slug }
      // });
      this.$router.go(-1);
    },
    toFixedTwoDigit(amount) {
      return parseFloat(amount).toFixed(2);
    },
    SubmitService() {
      if (this.getArchivalCustom) {
        let custom_date = this.getArchivalCustomDate;
        let custom_price = this.getArchivalCustomPrice;
        if (custom_date == "") {
          this.$toastr.e("Please set the date");
        }
        if (custom_price == "" || custom_price == 0) {
          this.$toastr.e("Please set the required price");
        } else {
          var mytoken = document.getElementById("mytoken");

          mytoken =    this.currentUser.u.roles != "admin"?mytoken.innerHTML:"";
          if (this.getPaymentType == "C") {
            this.loadingMessage =
              "please wait <br> While we process your payment...";

            if (mytoken == "" || mytoken == null) {
              if(this.currentUser.u.is_admin ){
                  this.$toastr.e(
                  "Plese use a Abbreviation Code"
                );
              
                }
                else{
                this.$toastr.e(
                  "The card number entered is not compliant, please check and re-enter."
                );
                }
            } else {
              if (this.getIsHeartsPayment) {
                var expiremonth = document.getElementById("expiremonth")
                  .innerHTML;
                var expireyear = document.getElementById("expireyear")
                  .innerHTML;

                if (
                  mytoken == "" ||
                  mytoken == null ||
                  expiremonth == "" ||
                  expiremonth == null ||
                  expireyear == "" ||
                  expireyear == null
                  // this.getPaymentCardCvvNumber == ""
                ) {
                  this.$toastr.e("Card info is required");
                } else {
                  this.submit("submit_custom_archival");
                }
              } else {
                if (
                  mytoken == "" ||
                  mytoken == null ||
                  this.getPaymentCardHolderName == "" ||
                  this.getPaymentCardExpiry == "" ||
                  this.getPaymentCardCvvNumber == ""
                ) {
                  this.$toastr.e("Card info is required");
                } else {
                  this.submit("submit_custom_archival");
                }
              }
            }
          } else {
            this.loadingMessage =
              "please wait <br> Your archival is being set...";

            this.submit("submit_custom_archival");
          }
        }
      } else {
        if (this.getArchivalPeriodId != "") {
          mytoken = document.getElementById("mytoken");

          mytoken = this.currentUser.u.roles != "admin"?mytoken.innerHTML:"";
          if (this.updateAbbreviationCode != "") {
            if (!this.getAbbreviationCode.success) {
              this.$toastr.e(this.getAbbreviationCode.msg);
            } else {
              if (this.getPaymentType == "C") {
                this.loadingMessage =
                  "please wait <br> While we process your payment...";

                if (mytoken == "" || mytoken == null) {
                  if(this.currentUser.u.is_admin){
                  this.$toastr.e(
                  "Plese use a Abbreviation Code"
                );
              
                }
                else{
                this.$toastr.e(
                  "The card number entered is not compliant, please check and re-enter."
                );
                }
                } else {
                  if (this.getIsHeartsPayment) {
                    expiremonth = document.getElementById("expiremonth")
                      .innerHTML;
                    expireyear = document.getElementById("expireyear")
                      .innerHTML;

                    if (
                      mytoken == "" ||
                      mytoken == null ||
                      expiremonth == "" ||
                      expiremonth == null ||
                      expireyear == "" ||
                      expireyear == null
                      // this.getPaymentCardCvvNumber == ""
                    ) {
                      this.$toastr.e("Card info is required");
                    } else {
                      this.submit("submit_archival");
                    }
                  } else {
                    if (
                      mytoken == "" ||
                      mytoken == null ||
                      this.getPaymentCardHolderName == "" ||
                      this.getPaymentCardExpiry == "" ||
                      this.getPaymentCardCvvNumber == ""
                    ) {
                      this.$toastr.e("Card info is required");
                    } else {
                      this.submit("submit_archival");
                    }
                  }
                }
              } else {
                this.loadingMessage =
                  "please wait <br> Your appointment is being scheduled...";

                this.submit("submit_archival");
              }
            }
          } else {
            if (this.getPaymentType == "C") {
              this.loadingMessage =
                "please wait <br> While we process your payment...";
              if (mytoken == "" || mytoken == null) {
                if(this.currentUser.u.is_admin){
                  this.$toastr.e(
                  "Plese use a Abbreviation Code"
                );
              
                }
                else{
                this.$toastr.e(
                  "The card number entered is not compliant, please check and re-enter."
                );
                }
              } else {
                if (this.getIsHeartsPayment) {
                  var expiremonth2 = document.getElementById("expiremonth")
                    .innerHTML;
                  var expireyear2 = document.getElementById("expireyear")
                    .innerHTML;

                  if (
                    mytoken == "" ||
                    mytoken == null ||
                    expiremonth2 == "" ||
                    expiremonth2 == null ||
                    expireyear2 == "" ||
                    expireyear2 == null
                    // this.getPaymentCardCvvNumber == ""
                  ) {
                    this.$toastr.e("Card info is required");
                  } else {
                    this.submit("submit_archival");
                  }
                } else {
                  if (
                    mytoken == "" ||
                    mytoken == null ||
                    this.getPaymentCardHolderName == "" ||
                    this.getPaymentCardExpiry == "" ||
                    this.getPaymentCardCvvNumber == ""
                  ) {
                    this.$toastr.e("Card info is required");
                  } else {
                    this.submit("submit_archival");
                  }
                }
              }
            } else {
              this.loadingMessage =
                "please wait <br> Your appointment is being scheduled...";
              this.submit("submit_archival");
            }
          }
        } else if (this.getArchivalRenewDate == "") {
          this.$toastr.w("please select date ");
        } else {
          this.submit("submit_date");
        }
      }
    },
    reset() {
      this.$store.commit(SET_ARCHIVAL_RENEW_DATE, "");
      this.$store.commit(STORE_ARCHIVAL_DATE, "");
      this.$store.commit(SET_ARCHIVAL_PERIOD_ID, "");
      this.$store.commit(UPDATE_ABBREVIATION_CODE, "");
      this.$store.commit(SET_ARCHIVAL_TOTAL_RATE_AMOUNT, 0);
      this.$store.commit(SET_PAYMENT_CARD_PROPERTY, "");
      this.$store.commit(SET_PAYMENT_CARD_TYPE, "");
      this.$store.commit(SET_PAYMENT_CARD_NUMBER, "");
      this.$store.commit(SET_PAYMENT_CARD_HOLDER_NAME, "");
      this.$store.commit(SET_PAYMENT_CARD_ADDRESS, "");
      this.$store.commit(SET_PAYMENT_CARD_CITY, "");
      this.$store.commit(SET_PAYMENT_CARD_CVV_NUMBER, "");
      this.$store.commit(SET_PAYMENT_CARD_EXPIRY_DATE, "");
      this.$store.commit(CARD_COUNTRY, "United States");
      this.$store.commit(CARD_STATE, "");
      this.$store.commit(SET_PAYMENT_CARD_NUMBER_PHONE, "");
      this.$store.commit(SET_PAYMENT_CARD_NUMBER_POSTAL, "");
      this.$store.commit(UPDATE_PAYMENT_TYPE, "C");
      this.$store.commit(UPDATE_ABBREVIATION_CODE, "");
      this.$store.commit(SET_ARCHIVAL_CUSTOM_DATE, "");
      this.$store.commit(SET_ARCHIVAL_CUSTOM_PRICE, 0);
      this.$store.commit(SET_ARCHIVAL_CUSTOM, false);

      this.paymentMethod = true;
      this.$store.commit(SET_CHECK_F_ABBREVIATION_CODE, {
        data: { show_payment_option: true },
        success: "none"
      });
    },

    submit(submit_type) {
      this.errorMsg = "";
      let updateAbbreviationCode = "";
      if (this.getAbbreviationCode.data.length == 0) {
        updateAbbreviationCode = "";
      } else {
        updateAbbreviationCode = this.updateAbbreviationCode;
      }
      // this.saveDataLoader = true;

      var formData = {
        id: this.$route.params.enroll_id,
        selected_term: this.getArchivalPeriodId,
        selected_date: this.getArchivalRenewDate,
        type: this.getPaymentType,
        abbreviation_code: updateAbbreviationCode,
        custom_archival: this.getArchivalCustom,
        custom_archival_date: this.getArchivalCustomDate,
        custom_archival_price: this.getArchivalCustomPrice
      };

      if (submit_type != "submit_date") {
        var mytoken = document.getElementById("mytoken");
       
            
        let token = this.currentUser.u.roles != "admin"?mytoken.innerHTML:"";
        if (this.getPaymentType == "C") {
          if (this.getIsHeartsPayment) {
            var expiremonth = document.getElementById("expiremonth");
            var expireyear = document.getElementById("expireyear");
            var token_type = document.getElementById("token_type");
            var last_four = document.getElementById("last_four");
            var card_type = document.getElementById("card_type");
            formData.payment_details = {
              hearts_token: token,
              expire_month: expiremonth.innerHTML,
              expire_year: expireyear.innerHTML,
              // card_cvv_no: this.getPaymentCardCvvNumber,
              postal: this.getPaymentCardPostalZip,
              token_type: token_type.innerHTML,
              last_four: last_four.innerHTML,
              card_type: card_type.innerHTML,
              card_address: this.getPaymentCardHolderAddress
            };
          } else {
            formData.payment_details = {
              card_number: token,
              card_holder_name: this.getPaymentCardHolderName,
              card_expiry: this.getPaymentCardExpiry,
              card_cvv_no: this.getPaymentCardCvvNumber,
              card_type: this.getPaymentCardType,

              card_address: this.getPaymentCardHolderAddress,
              card_city: this.getPaymentCardHolderCity,
              card_country: this.getPaymentCardHolderCountry,
              card_state: this.getPaymentCardHolderState,
              phone:
                this.getPaymentCardPhonecode.dial_code +
                this.getPaymentCardPhone,
              postal: this.getPaymentCardPostalZip
            };
          }
        }
      }
      if (this.getArchivalCustom) {
        var newDate = this.getArchivalCustomDate;
      } else {
        newDate = this.getArchivalRenewDate;
      }
      // console.log(newDate,this.getArchivalCustomDate,this.getArchivalCustom, this.getArchivalCustomPrice);

      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You want to change date to " + newDate,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "No"
        })
        .then(willChange => {
          if (willChange.isConfirmed) {
            this.saveDataLoader = true;
            this.$store
              .dispatch(CHANGE_ARCHIVAL_DATE, formData)
              .then(() => {
                this.$toastr.s("Enrollment updated successfully");
                this.saveDataLoader = false;
                this.$emit("closemodal");

                this.goBack();
              })
              .catch(err => {
                this.$toastr.e(err.data.msg);
                this.saveDataLoader = false;
              });
          }
        });
    }
    // if (this.show_term == "1") {
    //   if (this.selected == "") {
    //     this.$toastr.w("please select one term ");
    //   } else {
    //     // let message = ;
    //     this.$swal
    //       .fire({
    //         title: "Are you sure?",
    //         text:
    //           "You want to change with  " + this.selected.duration + " Years",
    //         icon: "warning",
    //         showCancelButton: true,
    //         confirmButtonColor: "#3085d6",
    //         cancelButtonColor: "#d33",
    //         confirmButtonText: "Yes",
    //         cancelButtonText: "No"
    //       })
    //       .then(willChange => {
    //         if (willChange.isConfirmed) {
    //           var data_ = {
    //             a_type: "term",
    //             id: this.$route.params.enroll_id,
    //             selected_term: this.selected.id
    //           };

    //           this.$store
    //             .dispatch(CHANGE_ARCHIVAL_DATE, data_)
    //             .then(() => {
    //               this.$toastr.s("Enrollment updated successfully");
    //               this.$emit("closemodal");

    //               this.goBack();
    //             })
    //             .catch(err => {
    //               this.$toastr.e(err.data.msg);
    //             });
    //         }
    //       });
    //   }
    // } else if (this.show_term == "0") {
    //   if (this.selectedDate == "") {
    //     this.$toastr.w("please select a date ");
    //   } else {
    //     this.$swal
    //       .fire({
    //         title: "Are you sure?",
    //         text: "You want to change archvial date to  " + this.selectedDate,
    //         icon: "warning",
    //         showCancelButton: true,
    //         confirmButtonColor: "#3085d6",
    //         cancelButtonColor: "#d33",
    //         confirmButtonText: "Yes",
    //         cancelButtonText: "No"
    //       })
    //       .then(willChange => {
    //         if (willChange.isConfirmed) {
    //           var data_ = {
    //             a_type: "date",
    //             id: this.$route.params.enroll_id,
    //             getArchivalRenewDate: this.selectedDate
    //           };

    //           this.$store
    //             .dispatch(CHANGE_ARCHIVAL_DATE, data_)
    //             .then(() => {
    //               this.$toastr.s("Enrollment updated successfully");

    //               this.$emit("closemodal");
    //               this.goBack();
    //             })
    //             .catch(err => {
    //               this.$toastr.e(err.data.msg);
    //             });
    //         }
    //       });
    //   }
    // }

    // getTimeZone() {
    //   var offset = new Date().getTimezoneOffset(),
    //     o = Math.abs(offset);
    //   return (
    //     (offset < 0 ? "+" : "-") +
    //     ("00" + Math.floor(o / 60)).slice(-2) +
    //     ":" +
    //     ("00" + (o % 60)).slice(-2) +
    //     " " +
    //     Intl.DateTimeFormat().resolvedOptions().timeZone
    //   );
    // },
  },
  filters: {
    timeFormat: function(value, date) {
      var fullDate = date + " " + value;
      var a = moment(fullDate).format("hh:mm a");
      return a;
      // var a = moment.utc(fullDate, 'YYYYMMDD HH:mm:ss');
      // return a.clone().tz(timezone).format('hh:mm a');
    }
  }
};
</script>
<style lang="scss" scoped>
.alert-danger-dim {
  background: #f7cece90 !important;
}
#book-footer-temp {
  .btn {
    height: 40px !important;
    float: right;
  }
  .total_amount {
    display: inline-block;
    .btn-right {
      float: right !important;
    }
  }
}
.alert-custom {
  /* width: 78%; */
  /* position: relative; */
  /* top: -15%; */
  /* left: 4%; */
  /* margin-left: 12%; */
  margin-top: 5%;
  z-index: 1;
  background: #dc3545 !important;
}
</style>
